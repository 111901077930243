<template>
  <div class="articlePage">
    <div class="listMenu">
      <Dropdown trigger="click" @on-click="toLink">
        <a href="javascript:void(0)">
          文章分类
          <Icon type="ios-arrow-down"></Icon>
        </a>
        <DropdownMenu slot="list">
          <DropdownItem v-for="(item, index) in getConfig.articleTypeList" v-if="item.children.length === 0" :name="item.link"> {{ item.type }}</DropdownItem>
          <Dropdown placement="right-start" v-else>
            <DropdownItem>
              {{ item.type }}
              <Icon type="ios-arrow-forward"></Icon>
            </DropdownItem>
            <DropdownMenu slot="list">
              <DropdownItem v-for="(x, index) in item.children" :name="x.link">{{ x.type }}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </DropdownMenu>
      </Dropdown>
    </div>
    <div class="articleList">
      <div class="listItem" v-for="(item, index) in pageList" :key="index" @click="linkToArticle(item.link)">
        <img :src="item.cover || img">
        <div class="listCon">
          <div class="conHead">
            <p>{{ item.name }}</p>
            <span>{{ getTime(item.createTime) }}</span>
          </div>
          <span>{{ item.dec }}</span>
        </div>
      </div>
      <div class="pageCut">
        <Page @on-change="pageCut" :total="page.count" :page-size="page.size" />
      </div>
    </div>
  </div>
</template>

<script>
  import list from "./list"
  export default list
</script>

import { mapGetters } from "vuex";
import img from  "../../../assets/img/site/artcle.png"
export default {
  data() {
    return {
      webSite: {},
      menuIdx: -1,
      typeArticle: [],
      articleList: [],
      pageList: [],
      img:img,
      page: {
        count:0,
        size: 8,
      }
    };
  },
  computed: {
    ...mapGetters(["getConfig"])
  },
  watch: {
    $route() {
      this.pageList = [];
      this.init();
    },
    getConfig() {
      this.pageList = [];
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    getListAct(type) {
      if (type) {
        return {
          backgroundColor: this.getConfig.pageColor,
          color: "#fff"
        }
      }
    },
    getClass(item) {
      return this.$route.query.id === item.link ? "menuPAct" : "";
    },
    init() {
      let idx = 0;
      for (let x of this.getConfig.articleTypeList) {
        for (let i of x.children) {
          if (this.$route.query.id === i.link) {
            this.menuIdx = idx;
          }
        }
        if (this.$route.query.id === x.link) {
          this.menuIdx = idx;
        }
        idx += 1;
      }
      this.pageCut(1);
    },
    pageCut(pageIdx){
      let baseList = [];
      if (this.$route.query.id === "list") {
        baseList = this.getConfig.articleList;
      }else  {
        for (let item of this.getConfig.articleList) {
          if (item.type === this.$route.query.id) {
            baseList.push(item);
          }
        }
      }
      let page = baseList.length > this.page.size ? pageIdx : 1;
      let pageLent = page * this.page.size > baseList.length ? baseList.length : page * this.page.size;
      this.pageList = baseList.slice(page * this.page.size - this.page.size, pageLent);
      this.page.num  = page;
      this.page.count = baseList.length
    },
    menuSel(idx) {
      this.menuIdx = idx;
    },
    linkToArticle(id) {
      this.$router.push({ name: "wapArticle", query: { id: id } });
    },
    getTime(time) {
      if (!time) {
        return "";
      }
      let d = new Date(time);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let date = d.getDate();
      // let hour = d.getHours();
      // let minute = d.getMinutes();
      // let second = d.getSeconds();
      return year + "/" + month + "/" + date;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    toLink(item) {
      this.$router.push({ name: "wapList", query: { id: item } });
    }
  }
};